// src/App.tsx
import React, { useState, useEffect } from 'react';
import './App.css';
import BingoCard from './components/BingoCard';

const wordList = [
"Answer",
"Javascript",
"jobs. \n murphyusa.com",
"Murphy Arts District",
"Pin",
"Y2K Bug",
"Card Reader IN Dispenser",
"Laptop",
"Uncloud",
"Worm",
"Network",
"Mouse",
"People",
"Central Processing Unit",
"Hardware and Software",
"Scrambling/ \n Doing Nothing",
"Collusion",
"Word Wrapping",
"Links",
"Lock",
"Human",
"Keylogging",
"Social Engineering",
"Exploitation",
"Management",
"Download, Install",
"Bad Guy",
"A Safe embedded into a floor",
"Virtual Local Area Network",
"Mandatory vacation",
"Username and Password",
"Round Up",
"Social Security Nummber (SSN)",
"Confidentiality, Integrity, Availability",
"Charging",
"Malware",
"Individual Development Plan (IDP)",
"Universal Serial Bus",
"Adults",
"Docking",
"Data, Data",
"Glitch, Error, Bug",
"Clickbait",
"Wi-Fi",
"Smishing",
"Vitual Private Network",
"Monitor",
"Video Home System",
"Zero-Day Exploit",
"BoomBox",
"VCR",
"Bluetooth",
"Sticky or Post-it",
"Tuition",
"Balance, Purchase",
"Murphy Drive Rewards",
"Vulnerable",
"Fraud",
"Network Firewall",
"Microwaves",
"Everyone in the organization",
"Every 90 days",
"Access authorization",
"We Appreciate You",
"Global Protect",
"Spyware",
"Firewall",
"Crop",
"Fence",
"Phishing",
"Passwords",
"Manager",
"Vishing",
"Unacceptable",
"Smartlocks",
"Good Guy"

];

// Helper function to shuffle and create the Bingo card
const shuffleArray = (array: string[]): string[] => {
  return array.sort(() => Math.random() - 0.5);
};

// Function to generate a 5x5 Bingo card with unique words (no duplicates)
const generateBingoCard = (): string[][] => {
  const shuffledWords = shuffleArray(wordList); // Shuffle the word list
  const selectedWords = new Set<string>(); // Set to keep track of unique words
  const card: string[][] = [];
  let index = 0;

  for (let i = 0; i < 5; i++) {
    const row: string[] = [];
    for (let j = 0; j < 5; j++) {
      if (i === 2 && j === 2) {
        row.push("Free Space"); // Free space in the center
      } else {
        // Ensure each word is unique
        while (selectedWords.has(shuffledWords[index])) {
          index++; // Skip over duplicates
        }
        selectedWords.add(shuffledWords[index]);
        row.push(shuffledWords[index]);
        index++;
      }
    }
    card.push(row);
  }

  return card;
};


const App: React.FC = () => {
  const [bingoCard, setBingoCard] = useState<string[][]>(generateBingoCard());
  const [markedWords, setMarkedWords] = useState<Set<string>>(new Set());
  const [hasBingo, setHasBingo] = useState(false); // State to track Bingo
  const [showPopup, setShowPopup] = useState(false); // State for showing the Bingo popup
  const [showConfetti, setShowConfetti] = useState(false); // State for showing confetti

  // Automatically mark "Free Space" when the card is generated
  useEffect(() => {
    setMarkedWords((prevMarkedWords) => {
      return new Set(prevMarkedWords).add("Free Space");
    });
  }, [bingoCard]); 

  // Function to check for Bingo
  const checkBingo = (markedWords: Set<string>) => {
    const cardSize = 5;
    const cardRows = bingoCard;

    // Check all rows
    for (let row = 0; row < cardSize; row++) {
      if (cardRows[row].every(word => markedWords.has(word))) {
        return true;
      }
    }

    // Check all columns
    for (let col = 0; col < cardSize; col++) {
      let columnWin = true;
      for (let row = 0; row < cardSize; row++) {
        if (!markedWords.has(cardRows[row][col])) {
          columnWin = false;
          break;
        }
      }
      if (columnWin) return true;
    }

    // Check diagonals
    let diagonalWin1 = true;
    let diagonalWin2 = true;
    for (let i = 0; i < cardSize; i++) {
      if (!markedWords.has(cardRows[i][i])) {
        diagonalWin1 = false;
      }
      if (!markedWords.has(cardRows[i][cardSize - i - 1])) {
        diagonalWin2 = false;
      }
    }
    return diagonalWin1 || diagonalWin2;
  };

  const handleWordClick = (word: string) => {
    setMarkedWords((prev) => {
      const updatedMarkedWords = new Set(prev);
      if (updatedMarkedWords.has(word)) {
        updatedMarkedWords.delete(word); // Unmark if already marked
      } else {
        updatedMarkedWords.add(word); // Mark if not already marked
      }

      // Check for Bingo *after* marking/unmarking
      if (checkBingo(updatedMarkedWords)) {
        setHasBingo(true); // Trigger Bingo
        setShowPopup(true); // Show Bingo popup
        setShowConfetti(true); // Show confetti

        setTimeout(() => {
          setShowPopup(false); // Hide the Bingo popup after 5 seconds
        }, 5000);

        setTimeout(() => {
          setShowConfetti(false); // Hide confetti after 10 seconds
        }, 10000);
      }

      return updatedMarkedWords;
    });
  };

// Function to generate a random percentage between 0% and 100%
const getRandomPercentage = (): string => {
  return `${Math.floor(Math.random() * 100)}%`;
};

  // Function to reset the marked checkmarks
  const resetCheckmarks = () => {
    const newMarkedWords = new Set<string>().add("Free Space"); // Reset checkmarks except for "Free Space"
    setMarkedWords(newMarkedWords);
    setHasBingo(false); // Hide Bingo popup if it's showing
    setShowPopup(false); // Hide popup if shown
    setShowConfetti(false); // Hide confetti if shown
  };

  // Function to generate a new Bingo card
  const generateNewCard = () => {
    setBingoCard(generateBingoCard());
    setMarkedWords(new Set<string>().add("Free Space")); // Mark "Free Space" on the new card
    setHasBingo(false); // Reset Bingo state
    setShowPopup(false); // Hide popup if shown
    setShowConfetti(false); // Hide confetti if shown
  };

  return (
    <div className="App">
      {/* Conditionally render the Bingo popup and confetti */}
      {hasBingo && (
        <>
          {showPopup && <div className="bingo-popup">BINGO!</div>}
          {showConfetti && (
            <>
              <div className="confetti" style={{ left: "10%" }}></div>
              <div className="confetti" style={{ left: "50%" }}></div>
              <div className="confetti" style={{ left: "70%" }}></div>
              <div className="confetti" style={{ left: "30%" }}></div>
              <div className="confetti" style={{ left: "90%" }}></div>
            </>
          )}
        </>
      )}
      <div className="logos">
        <img src="/musa.png" alt="Logo 1" className="logo" />
        <img src="/qc.png" alt="Logo 2" className="logo" />
      </div>
      <h1 className="title">Cybersecurity Bingo</h1>
      <BingoCard
        numbers={bingoCard}
        onNumberClick={handleWordClick}
        markedNumbers={markedWords}
      />

      {/* Buttons for resetting checkmarks and generating a new card */}
      <div className="controls">
        <button className="reset-button" onClick={resetCheckmarks}>Clear Card</button>
        <button className="new-card-button" onClick={generateNewCard}>Generate New Card</button>
      </div>
    </div>
  );
};

export default App;