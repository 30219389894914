// src/components/BingoCard.tsx
import React from 'react';

interface BingoCardProps {
  numbers: string[][];
  onNumberClick: (word: string) => void;
  markedNumbers: Set<string>;
}

const BingoCard: React.FC<BingoCardProps> = ({ numbers, onNumberClick, markedNumbers }) => {
  return (
    <div className="bingo-card">
      {numbers.map((row, rowIndex) => (
        <div key={rowIndex} className="bingo-row">
          {row.map((word) => (
            <button
              key={word}
              className={`bingo-number ${markedNumbers.has(word) ? 'marked' : ''} ${word === 'Free Space' ? 'free-space' : ''}`}
              onClick={() => onNumberClick(word)}
              disabled={word === 'Free Space'}
            >
              {word}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default BingoCard;
